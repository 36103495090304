<template>
    <div class="content">
        <table>
            <thead>
                <tr id="top">
                    <th scope="col" style="width: 70px">No</th>
                    <th scope="col">Depositor</th>
                    <th scope="col">Weight (gr)</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Detail</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(inventory, i) in dataTransaction" :key="i">
                    <td data-label="No">{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                    <td data-label="Trade Match Id">{{ inventory.depositor }}</td>
                    <td data-label="weight">{{ inventory.weight }}</td>
                    <td data-label="no bp batam">{{ inventory.quantity }}</td>
                    <td data-label="detaol">
                        <router-link :to="'/off-exchange/inventory/' + inventory.depositor">
                            <button type="button" class="btn btn-outline-info btn-sm">Detail</button>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <b-pagination
            v-model="currentPage"
            :total-rows="lengthData"
            :per-page="perPage"
            @change="handlePageChange"
            aria-controls="new-table"
            first-number
            last-number
            v-show="lengthData > 10"
        ></b-pagination>
    </div>
</template>

<script>
import axios from "axios";
// import ExportDeposit from "../components/ExportDeposit.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
    // components: { ExportDeposit },
    data() {
        return {
            dataTransaction: [],
            type: "text",
            typeDeposit: "offexchange",
            pageOfItems: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            offset: 0,
            data: [],
            form: {
                startDate: "",
                endDate: "",
                depositor: "",
            },
            toggleDownload: false,
            isHideDeposite: false,
        };
    },
    mounted() {
        this.getData(this.currentPage, this.typeDeposit);
    },
    methods: {
        openMenu() {
            this.toggleDownload = !this.toggleDownload;
        },
        handlePageChange(value) {
            this.getData(value, this.typeDeposit);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        filterTypeDeposit(value) {
            this.typeDeposit = value;
            this.getData(this.currentPage, value);
        },
        getData(value, typeDeposit) {
            let offset = (value - 1) * this.perPage;
            let url;
            let role = sessionStorage.getItem("role");
            if (role == 2 || role == 3) {
                url = "v2/clearing/admin/abx/get/list/summary_inventory?startDate&endDate&limit&offset" + offset + "&type=" + typeDeposit;
            } else {
                url = "v2/clearing/admin/get/list/summary_inventory?startDate&endDate&limit&offset=" + offset + "&type=" + typeDeposit;
            }
            axios.get(url).then((res) => {
                this.dataTransaction = res.data.data.body;
                this.lengthData = res.data.data.count;
            });
        },
        downloadDeposit() {
            // let fileName = "deposit-confirmation.pdf";
            let dataId = document.getElementById("downloadMenu");
            let pdf = new jsPDF({
                orientation: "portrait",
                format: "a4",
            });
            html2canvas(dataId, { useCORS: true, scale: 1 }).then((canvas) => {
                var margin = 8;
                var imgWidth = 210 - 2 * margin;
                var imgHeight = (canvas.height * imgWidth) / canvas.width;
                var pageHeight = 295;
                var heightLeft = imgHeight;
                let img = canvas.toDataURL("image/png", 1);

                var position = 1;
                pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                pdf.save("deposit-confirmation");
            });
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

/* .wrap-content {
  width: 98%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 25px;
} */

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
}

select,
input {
    border-radius: 3px;
    border: 1px solid #a5a5a5a5;
    padding: 3px;
    width: 100%;
    margin-bottom: 0.5rem;
}

input:last-child {
    margin-bottom: unset;
}

.wrap-download {
    position: relative;
}

.menu-download {
    position: absolute;
    margin-bottom: 1rem;
    background: #fff;
    width: 17rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 1rem;
    font-size: 13px;
    right: 0;
    top: 42px;
}

.nav-tab {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #636366;
    padding: 0.8rem 0;
}

.tab {
    padding: 0.8rem 1rem;
    cursor: pointer;
}

.tab:hover,
.activeTab {
    color: var(--scondary-color);
    border-bottom: 2px solid var(--scondary-color);
}
</style>
